import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {isNil} from 'lodash-es';
import {cache} from "./cache.operator";
import {DateTime} from "luxon";

export interface User {
  id: number;
  personId: number | null; // null if system user
  personManagementId?: string;
  vorname: string | null;
  nachname: string | null;
  geburtsdatum: DateTime | null;
}

const roleUrl = 't-it-s/rest/basis/v1.0/sec/auth/role';
const userUrl = 't-it-s/rest/basis/v1.0/sec/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  private static toUser(response: any): User {
    return {
      id: response.userId,
      ...response
    } as User;
  }

  loadUser(id: number | string): Observable<User> {
    if (isNil(id)) {
      return EMPTY;
    }
    return this.http.get<User>(`${userUrl}/${id}`)
      .pipe(
        map(UserService.toUser)
      );
  }

  getUser(id: number | string): Observable<User> {
    if (isNil(id)) {
      return EMPTY;
    }
    return this.loadUser(id).pipe(cache(`user:${id}`));
  }

  getUsers(filter: { [key: string]: any | any[] }): Observable<User[]> {
    let params = new HttpParams();
    for (const key of Object.keys(filter)) {
      params = params.set(key, filter[key]);
    }
    return this.http.get<User[]>(userUrl, {params})
      .pipe(map((users: any[]) => users.map(UserService.toUser)));
  }

  /**
   * Returns the current logged in user
   */
  getCurrentUser(): Observable<User> {
    return this.http.get(`${userUrl}/current`)
      .pipe(
        map(UserService.toUser)
      ).pipe(cache('currentUser'));
  }
}
