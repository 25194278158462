import {Component} from '@angular/core';
import {ActivationEnd, Router} from "@angular/router";
import {AsyncPipe, DatePipe} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";
import {Button} from "primeng/button";
import {catchError, filter, map, switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {environment} from "../../../environments/environment";
import {DialogService, DynamicDialog} from "primeng/dynamicdialog";
import {
  CreateCommercialCustomerDialogComponent
} from "../create-commercial-customer-dialog/create-commercial-customer-dialog.component";
import {
  CreateCustomerRedirectDialogComponent
} from "../create-customer-redirect-dialog/create-customer-redirect-dialog.component";
import {Person, PersonService} from "../../services/person.service";

@Component({
  selector: 'app-subheader-buttons',
  standalone: true,
  imports: [
    TooltipModule,
    Button,
    AsyncPipe,
    DatePipe,
    DynamicDialog,
  ],
  providers: [DialogService],
  templateUrl: './subheader-buttons.component.html',
  styleUrl: './subheader-buttons.component.scss'
})
export class SubheaderButtonsComponent {

  protected readonly environment = environment;
  loading = false;

  person$: Observable<Person | null>;

  constructor(private router: Router,
              private personService: PersonService, private dialogService: DialogService) {
    this.person$ = router.events.pipe(
      filter((data) => data instanceof ActivationEnd),
      map((data: ActivationEnd) => data.snapshot?.params?.personManagementId),
      switchMap((id) => {
        if (!id) {
          return of(null)
        } else {
          return this.personService.getPerson(id);
        }
      }),
      catchError((_err, caught) => {
        return caught;
      }));
  }

  onBusinessSide(): boolean {
    return this.router.url.startsWith('/businesses');
  }

  createCommercialCustomer() {
    this.dialogService.open(CreateCommercialCustomerDialogComponent, {
      header: 'Gewerbekunden anlegen',
      width: '612px'
    }).onClose
      .subscribe(result => {
        if (result && result.id) {
          this.router.navigate(['businesses', result.personManagementId]);
        }
      });
  }

  createPerson() {
    this.dialogService.open(CreateCustomerRedirectDialogComponent, {
      header: 'Person anlegen',
      width: '600px'
    });
  }

  openInCrm(personId: number) {
    window.open(`${environment.crmUrl}/t-it-s/redirect?typ=kunde&id=${personId}`, '_blank');
  }

  back () {
    this.router.navigate([this.onBusinessSide() ? 'businesses' : 'customers']);
  }
}
