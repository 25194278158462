const DEV_CRM_URL = 'https://crmd1.taures.de:8443';
const PROD_CRM_URL = 'https://crm.taures.de';

const DEV_RETIREMENT_CALCULATOR_URL = 'https://dev-retirement-calculator.k8s.taures.de:8443';
const PROD_RETIREMENT_CALCULATOR_URL = 'https://altersvorsorgerechner.taures.de';

const DEV_ECHSE_URL = 'https://dev-echse.taures.de:8443';
const PROD_ECHSE_URL = 'https://echse.taures.de';

const DEV_FIRST_INFORMATION_URL = 'https://dev-erstinformation.taures.de:8443';
const PROD_FIRST_INFORMATION_URL = 'https://erstinformation.taures.de';

const DEV_DDE_URL = 'https://dev-dde.taures.de:8443';
const PROD_DDE_URL = 'https://dde.taures.de';

const DEV_POTENTIAL_PLANER_URL = 'https://dev-potentialplaner.taures.de:8443';
const PROD_POTENTIAL_PLANER_URL = 'https://potentialplaner.taures.de';

const DEV_DOCS_URL = 'https://dev-docs.taures.de:8443';
const PROD_DOCS_URL = 'https://docs.taures.de';

const DEV_RVA_URL = 'https://dev-rva.taures.de:8443';
const PROD_RVA_URL = 'https://rva.taures.de';

const DEV_UNTERSCHRIFT_URL = 'https://dev-unterschrift.taures.de:8443';
const PROD_UNTERSCHRIFT_URL = 'https://unterschrift.taures.de';

const DEV_ISSUER_URL = 'https://dev-sso.taures.de:8443/auth/realms/TauRes-Internal';
const PROD_ISSUER_URL = 'https://sso.taures.de/auth/realms/TauRes-Internal';

const devEnvironment = location.origin.includes('dev-');

export const environment = {
  production: true,
  oauth: {
    issuer: devEnvironment ? DEV_ISSUER_URL : PROD_ISSUER_URL,
    clientId: 'ci_konzept-frontend',
    scope: 'openid email',
    responseType: 'code'
  },
  retirementCalculatorUrl: devEnvironment ? DEV_RETIREMENT_CALCULATOR_URL : PROD_RETIREMENT_CALCULATOR_URL,
  echseUrl: devEnvironment ? DEV_ECHSE_URL : PROD_ECHSE_URL,
  firstInformationUrl: devEnvironment ? DEV_FIRST_INFORMATION_URL : PROD_FIRST_INFORMATION_URL,
  ddeUrl: devEnvironment ? DEV_DDE_URL : PROD_DDE_URL,
  potentialPlanerUrl: devEnvironment ? DEV_POTENTIAL_PLANER_URL : PROD_POTENTIAL_PLANER_URL,
  docsUrl: devEnvironment ? DEV_DOCS_URL : PROD_DOCS_URL,
  rvaUrl: devEnvironment ? DEV_RVA_URL : PROD_RVA_URL,
  crmUrl: devEnvironment ? DEV_CRM_URL : PROD_CRM_URL,
  unterschriftUrl: devEnvironment ? DEV_UNTERSCHRIFT_URL : PROD_UNTERSCHRIFT_URL,
};
