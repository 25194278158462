import {coerceBooleanProperty, coerceNumberProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {isNil} from 'lodash-es';
import {FloatLabelModule} from "primeng/floatlabel";
import {AutoComplete, AutoCompleteModule, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {AsyncPipe} from "@angular/common";
import {User, UserService} from 'src/app/services/user.service';
import {AgentInfo, AgentService} from 'src/app/services/agent.service';
import {Person, PersonService} from "../../services/person.service";
import {InputTextModule} from "primeng/inputtext";
import {Fluid} from "primeng/fluid";

export type Mode = 'user' | 'customer' | 'agent';

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: 'user-autocomplete.component.html',
  styleUrls: ['user-autocomplete.component.scss'],
  standalone: true,
  imports: [
    FloatLabelModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    AsyncPipe,
    InputTextModule,
    Fluid
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAutocompleteComponent implements ControlValueAccessor, OnInit, OnDestroy {

  disabled = false;
  filteredElements: Observable<User[] | Person[] | AgentInfo[]> | undefined;
  control: UntypedFormControl = new UntypedFormControl();
  showSpinner = false;
  displayName = UserAutocompleteComponent.displayName;
  @ViewChild('autocomplete', { static: true }) autocomplete: AutoComplete | undefined;
  @Input()
  placeholder: string = '';
  @Input()
  additionalFilter: { [key: string]: any | any[] } = {};
  /**
   * List of customer/user ids that should be ignored
   */
  @Input()
  ignore: number[] = [];
  private destroy = new Subject();
  private idToLoad: number | undefined;

  constructor(private userService: UserService,
              private personService: PersonService,
              private agentService: AgentService,
              @Optional() @Self() public ngControl: NgControl,
              private changeDetector: ChangeDetectorRef) {
    this.ngControl.valueAccessor = this;
    this.control.asyncValidator = this.ngControl.asyncValidator;
    this.control.validator = Validators.compose([(control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return typeof value !== 'object' ? {person: value} : null;
    }, this.ngControl.validator]);
  }

  @Input()
  set displayNameFunc(value: (c: User | Person) => string) {
    this.displayName = value;
  }

  private $mode: Mode = 'user';

  get mode() {
    return this.$mode;
  }

  @Input()
  set mode(value: Mode) {
    this.$mode = value;
    this.loadData();
  }

  private $required = false;

  get required() {
    return this.$required;
  }

  @Input()
  set required(value: boolean) {
    this.$required = coerceBooleanProperty(value);
  }

  get currentPersonId() {
    if (!this.control.value) {
      return null;
    }
    return this.mode === 'user' ? this.control.value.personId : this.control.value.id;
  }

  private static displayName(c: User | Person | AgentInfo): string {
    let name = '';
    if (c && c.nachname) {
      name = c.nachname;
    }
    if (c && c.vorname) {
      if (name) {
        name += ', ';
      }
      name += c.vorname;
    }
    if (c && c.geburtsdatum) {
      name += ` (${c.geburtsdatum.toFormat('dd.MM.yyyy')})`;
    }
    return name;
  }

  private static parseName(value: string): string {
    if (value) {
      const match = value.match(/^(.*),/);
      if (match && match.length > 1) {
        return match[1];
      }
    }
    return value;
  }

  onChange: any = () => {
    // default function
  }
  onTouched: any = () => {
    // default function
  }

  ngOnInit(): void {
    this.filteredElements = this.control.valueChanges
      .pipe(
        tap(v => {
          if (v) {
            this.toggleSpinner(true);
          }
        }),
        debounceTime(500),
        switchMap(v => this.filter(v)),
        map((personsOrUsers: any[]) => personsOrUsers.filter(personOrUser => {
          let personId = personOrUser.id;
          if (this.mode === 'user') {
            personId = personOrUser.personId;
          }
          return this.ignore.indexOf(personId) === -1;
        }))
      );
  }

  ngOnDestroy(): void {
    this.destroy.next(undefined);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (!isDisabled && this.control.disabled) {
      this.control.enable();
    } else if (isDisabled && this.control.enabled) {
      this.control.disable();
    }
  }

  writeValue(value: number | string): void {
    if (isNil(value)) {
      this.control.reset();
      return;
    }
    if (value === this.currentPersonId) {
      return;
    }
    this.idToLoad = coerceNumberProperty(value);
    this.loadData();
  }

  onUserChange(event: AutoCompleteSelectEvent): void {
    const personOrUser = event.value;
    if (personOrUser) {
      this.onChange(this.mode === 'user' ? personOrUser.personId : personOrUser.id);
    }
  }

  // @ts-ignore
  private filter(value): Observable<User[] | Person[] | AgentInfo[]> {
    if (value) {
      if (!isNaN(Number(value))) {
        return this.filterById(value);
      } else if (typeof value === 'object') {
        this.toggleSpinner(false);
        return of([value]);
      } else {
        const queryFilter = {...this.additionalFilter, name: UserAutocompleteComponent.parseName(value)};
        if (this.mode === 'user') {
          return this.userService.getUsers(queryFilter).pipe(
            tap(() => this.toggleSpinner(false))
          );
        } else if (this.mode === 'customer') {
          return this.personService.getPersons(queryFilter).pipe(
            tap(() => this.toggleSpinner(false))
          );
        } else if (this.mode === 'agent') {
          return this.agentService.getAgentInfos(queryFilter).pipe(
            map(agents => agents.sort((a, b) => {
              let result = a.nachname.localeCompare(b.nachname);
              if (result === 0) {
                result = a.vorname.localeCompare(b.vorname);
              }
              if (result === 0) {
                // @ts-ignore
                result = a.geburtsdatum < b.geburtsdatum ? -1 : 1;
              }
              return result;
            })),
            tap(() => this.toggleSpinner(false))
          );
        }
      }
    } else {
      this.onChange();
    }
    this.toggleSpinner(false);
    return of([]);
  }

  filterById(id: string): Observable<User[] | Person[]> {
    if (this.mode === 'user') {
      return this.userService.getUser(id).pipe(
        tap(_ => this.toggleSpinner(false)),
        map(user => [user]),
        catchError(() => {
          this.toggleSpinner(false);
          return EMPTY;
        })
      );
    } else if (this.mode === 'customer' || this.mode === 'agent') {
      return this.personService.getPerson(id).pipe(
        tap(_ => this.toggleSpinner(false)),
        map(user => [user]),
        catchError(() => {
          this.toggleSpinner(false);
          return EMPTY;
        })
      );
    }
    return of([]);
  }

  private toggleSpinner(show: boolean) {
    this.showSpinner = show;
    this.changeDetector.markForCheck();
  }

  private loadData() {
    if (!this.mode) {
      return;
    }
    if (isNil(this.idToLoad)) {
      this.control.reset();
      this.changeDetector.markForCheck();
      return;
    }
    this.personService.getPerson(this.idToLoad).subscribe(person => {
      if (person && this.idToLoad === person.id) {
        this.control.reset(person);
        this.idToLoad = undefined;
      }
      if (!person) {
        this.idToLoad = undefined;
        this.control.reset();
        this.onChange();
        this.changeDetector.markForCheck();
      }
    });
  }
}
