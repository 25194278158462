<taures-toast></taures-toast>
@let unterschriftIcon = unterschriftIcon$ | async ;
<lib-taures-header [actionButtons]="unterschriftIcon && kundeId ? [unterschriftIcon] : []"  headerCaption="Konzepterstellung">
  <ng-template #subheader>
    <app-subheader-buttons></app-subheader-buttons>
  </ng-template>
  <div class="flex flex-column flex-grow-1 my-2">
    <router-outlet></router-outlet>
  </div>
</lib-taures-header>
<lib-taures-footer></lib-taures-footer>
