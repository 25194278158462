<ng-form>
  <div class="flex flex-column gap-1 pt-1">
    @for (consultant of consultantList; track consultant.personManagementId) {
      <div class="flex align-items-center gap-1">
        <p-radioButton [inputId]="consultant.personManagementId" [value]="consultant.personManagementId" [formControl]="user" variant="outlined"></p-radioButton>
        <label [for]="consultant.personManagementId">{{ displayName(consultant) }}</label>
      </div>
    }
  </div>
  <div class="flex justify-content-end gap-1 pt-1">
    <p-button outlined="true" label="Abbrechen" (click)="close()"></p-button>
    <p-button label="Weiter" (click)="submit()" [disabled]="user.invalid"></p-button>
  </div>
</ng-form>
