import {Injectable, Optional} from '@angular/core';
import {OAuthStorage} from 'angular-oauth2-oidc';
import {jwtDecode} from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class KeycloakTokenService {

  constructor(@Optional() private authStorage: OAuthStorage) {
  }

  private get decodedToken() {
    if (!this.authStorage) {
      return null;
    }
    const accessToken = this.authStorage.getItem('access_token');
    if (!accessToken) {
      return null;
    }
    return jwtDecode<any>(accessToken);
  }

  private getTokenProperty(key: string): string | null {
    const decodedToken = this.decodedToken;
    if (!decodedToken) {
      return null;
    }
    if (decodedToken[key] === undefined) {
      return null;
    }
    return decodedToken[key]
  }

  /**
   * Returns the given name or null
   */
  get givenName(): string | null {
    return this.getTokenProperty('given_name');
  }

  get familyName(): string | null {
    return this.getTokenProperty('family_name');
  }

  get name(): string | null {
    return this.getTokenProperty('name');
  }

  get email(): string | null {
    return this.getTokenProperty('email');
  }

  get personManagementId(): string | null {
    return this.getTokenProperty('personManagementId');
  }

  /**
   * Check if resource role is present
   */
  hasRole(role: string, resource?: string): boolean {
    const decodedToken = this.decodedToken;
    if (!decodedToken) {
      return false;
    }
    if (!decodedToken.resource_access) {
      return false;
    }

    const access = decodedToken.resource_access[resource || decodedToken.azp];
    return !!access && access.roles.indexOf(role) >= 0;
  }

  /**
   * Check realm_access roles
   */
  hasRealmRole(role: string): boolean {
    const decodedToken = this.decodedToken;
    if (!decodedToken) {
      return false;
    }
    const realm_access = decodedToken.realm_access;
    return !!realm_access && realm_access.roles.indexOf(role) >= 0;
  }
}
