import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {isNil} from 'lodash-es';
import {map} from 'rxjs/operators';
import {cache} from "./cache.operator";
import {DateTime} from "luxon";

export enum Status {
  DELETED = 'DELETED',
  MODIFIED = 'MODIFIED'
}

export interface Address {
  adresseco?: string;
  strasse?: string;
  nummer?: number;
  plz?: string;
  stadt?: string;
  land?: string;
}

export interface Contact {
  eintrag?: string;
  vorrang: boolean;
  typ: number;
}

export interface Person {
  id: number;
  personManagementId?: string;
  vorname: string;
  nachname: string;
  anrede?: string;
  geburtsdatum?: DateTime;
  adressen?: Address[];
  kommunikationsdaten?: Contact[];
  kundenstatus?: string;
  kunde: {
    betreuer: number;
    vp: number;
  };
}

const PERSON_URL = 't-it-s/rest/crm/v1.0/person/';
const PERSON_QUERY_URL = 't-it-s/rest/crm/v1.0/person/query';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(private readonly http: HttpClient) {
  }

  getPersons(filterConfig: { [key: string]: any | any[] }): Observable<Person[]> {
    let params = new HttpParams();
    for (const key of Object.keys(filterConfig)) {
      params = params.set(key, filterConfig[key]);
    }
    return this.http.get<Person[]>(PERSON_QUERY_URL, {params}).pipe(map(result =>
      result.sort((a, b) => {
        const aName = a.nachname + a.vorname;
        const bName = b.nachname + b.vorname;
        return aName.localeCompare(bName);
      })));
  }

  loadPerson(id: string | number): Observable<Person> {
    if (isNil(id)) {
      return EMPTY;
    }
    return this.http.get<Person>(PERSON_URL + id);
  }


  getPerson(id: string | number, skipCache: boolean = false): Observable<Person> {
    if (isNil(id)) {
      return EMPTY;
    }
    return this.loadPerson(id).pipe(cache(`person:${id}`, skipCache ? -1 : undefined));
  }
}


