<p-fluid>
  <ng-form class="flex flex-column gap-3">
    <div class="flex flex-column gap-4" [class.pt-3]="!hasDuplicateEmployer()" [formGroup]="form">
      @if (hasDuplicateEmployer()) {
        <taures-message header="Fehler" severity="error">
          Ein Arbeitgeber mit dem gleichen Namen existiert bereits im System.
        </taures-message>
      }
      <div class="flex gap-3">
        <app-user-autocomplete placeholder="Vertriebspartner"
                               [mode]="'agent'"
                               [additionalFilter]="includeConsultantFilter"
                               formControlName="vp"
                               class="w-6">
        </app-user-autocomplete>
        <app-user-autocomplete placeholder="Betreuer"
                               [mode]="'agent'"
                               [additionalFilter]="betreuerFilter"
                               formControlName="betreuer"
                               class="w-6">
        </app-user-autocomplete>
      </div>
      <p-floatlabel>
        <input id="name" pInputText type="text" maxlength="50" formControlName="name">
        <label for="name">Firmenname*</label>
      </p-floatlabel>
      <p-floatlabel>
        <input id="ansprechpartner" pInputText type="text" maxlength="50" formControlName="ansprechpartner">
        <label for="ansprechpartner">Ansprechpartner</label>
      </p-floatlabel>
      <div class="flex gap-3">
        <p-floatlabel class="w-8">
          <input id="straße" pInputText type="text" maxlength="150" formControlName="street" required>
          <label for="straße">Straße*</label>
          @if (hasNumberInStreet()) {
            <small class="text-red-500">Bitte prüfen: Zahlen im Straßennamen</small>
          }
        </p-floatlabel>
        <p-floatlabel class="w-4">
          <input id="hausnummer" pInputText type="text" maxlength="20" formControlName="number" required>
          <label for="hausnummer">Hausnummer*</label>
        </p-floatlabel>
      </div>
      <p-floatlabel>
        <input id="co" pInputText type="text" maxlength="100" formControlName="co">
        <label for="co">c/o</label>
      </p-floatlabel>
      <div class="flex gap-3">
        <p-floatlabel class="w-4">
          <input id="postleitzahl" pInputText type="text" maxlength="30" formControlName="postalCode" required>
          <label for="postleitzahl">Postleitzahl*</label>
        </p-floatlabel>

        <p-floatlabel class="w-8">
          <p-select class="p-0" fluid="true" id="ort" [options]="getCities()" [editable]="true" formControlName="city">
          </p-select>
          <label for="ort">Ort*</label>
        </p-floatlabel>
      </div>
      <p-floatlabel class="pb-1">
        <p-select id="land" fluid="true" [options]="sortedCountries" formControlName="country" optionLabel="name"
                  optionValue="code"
                  appendTo="body"></p-select>
        <label for="land">Land*</label>
      </p-floatlabel>
      <div class="flex gap-3">
        <p-floatlabel class="w-6">
          <input id="telefonnummer" pInputText type="text" maxlength="24" formControlName="phone">
          <label for="telefonnummer">Telefonnummer</label>
        </p-floatlabel>
        <p-floatlabel class="w-6">
          <input id="email" pInputText type="text" maxlength="100" formControlName="mail">
          <label for="email">E-Mail-Adresse</label>
        </p-floatlabel>
      </div>
    </div>
    <div class="flex justify-content-end gap-1 pt-1">
      <p-button outlined="true" label="Abbrechen" (click)="close()"></p-button>
      <p-button label="Anlegen" (click)="submit()" [disabled]="loading || form.invalid"></p-button>
    </div>
  </ng-form>
</p-fluid>
