import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AssistanceResult, ConsultantOfDialogComponent} from '../consultant-of-dialog/consultant-of-dialog.component';
import {PersonManagementService} from '../../services/person-management.service';
import log from 'loglevel';
import {SoftfairPushService} from "../../services/softfair-push.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ProblemDetail} from "../../interceptors/problem-detail-interceptor";
import {DialogService} from "primeng/dynamicdialog";
import {UserService} from "../../services/user.service";
import {ConfirmationService} from "primeng/api";
import {Person} from "../../services/person.service";

interface Benutzer {
  vorname: string,
  nachname: string,
  email: string
}

interface UebertragungStatus {
  success: boolean
  ssoUrl?: string,
  gesperrt: boolean,
  uebertragungErzwingbar?: boolean,
  gesperrtDurch?: Benutzer,
  fehlermeldung?: string
}

@Component({
  selector: 'app-softfair-push-dispatch',
  templateUrl: './softfair-push-dispatch.component.html',
  styleUrls: ['./softfair-push-dispatch.component.scss'],
  host: {class: 'flex flex-column flex-grow-1'}
})
export class SoftfairPushDispatchComponent implements OnInit {
  loading: boolean = true
  customer: Person;
  assistanceResult: AssistanceResult;
  message: string;
  private closeWindow$$: EventEmitter<any> = new EventEmitter<any>();

  constructor(private activatedRoute: ActivatedRoute,
              private softfairPushService: SoftfairPushService,
              private userService: UserService,
              private personManagementService: PersonManagementService,
              private confirmationService: ConfirmationService,
              private changeDetector: ChangeDetectorRef,
              public dialogService: DialogService) {
  }

  ngOnInit() {
    this.load();
    this.closeWindow$$.subscribe(() => this.closeWindow());
  }

  private load(uebertragungErzwingen: boolean = false) {
    this.message = 'Daten werden übertragen'
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.data,
      this.checkIfCurrentIsAssistant()
    ]).pipe(switchMap(([{productKey}, {customer}, assistance]) => {
      if (!assistance) {
        this.closeWindow()
      }
      Object.assign(this, {loading: true, productKey, customer})
      this.changeDetector.markForCheck();
      const imAuftragVonId = assistance.self ? null : assistance.superior;
      return this.softfairPushService.uebertrageKunde(productKey, this.customer.personManagementId, uebertragungErzwingen, imAuftragVonId)
        .pipe(map(uebertragungErgebnisDto => ({
            success: true,
            gesperrt: false,
            ssoUrl: uebertragungErgebnisDto.ssoUrl
          }) satisfies UebertragungStatus as UebertragungStatus)
        )
    }), catchError((error: HttpErrorResponse) => {
      this.message = null
      log.warn('Get customerLink failed', error);
      if (!(error.error instanceof ProblemDetail)) {
        return of({
          success: false,
          gesperrt: false,
          fehlermeldung: error.message as string
        } satisfies UebertragungStatus as UebertragungStatus)
      }
      const problemDetail = error.error;
      return of({
        success: false,
        gesperrt: 423 === problemDetail.status,
        fehlermeldung: problemDetail.detail,
        ...problemDetail.extensions
      } satisfies UebertragungStatus as UebertragungStatus);

    })).subscribe((uebertragungStatus: UebertragungStatus) => {
      log.debug('customerLink fetched', uebertragungStatus)
      if (!uebertragungStatus.success && uebertragungStatus.gesperrt) {
        return this.handleHaushaltLock(uebertragungStatus)
      }
      if (!uebertragungStatus.success) {
        Object.assign(this, {loading: false, message: null})
        this.changeDetector.markForCheck();
        this.confirmationService.confirm({
          key: 'softfair-warn',
          header: 'Achtung',
          message: uebertragungStatus.fehlermeldung,
          acceptLabel: 'OK',
          acceptButtonStyleClass: 'p-button-warn',
          rejectVisible: false,
          acceptEvent: this.closeWindow$$
        })
        return;
      }
      this.changeDetector.markForCheck();
      Object.assign(this, {loading: true, message: 'Du wirst nun weitergeleitet'})
      this.changeDetector.markForCheck();
      window.location.replace(uebertragungStatus.ssoUrl);
    })
  }

  checkIfCurrentIsAssistant(): Observable<AssistanceResult> {
    if (this.assistanceResult) return of(this.assistanceResult)
    return this.userService.getCurrentUser().pipe(
      switchMap(user => this.personManagementService.isAssistantOf(user.personManagementId).pipe(
        map(superiors => ({user, superiors}))
      )),
      switchMap(({user: currentUser, superiors: consultants}) => {
        this.loading = false;
        this.changeDetector.markForCheck()
        if (consultants && consultants.length) {
          return this.dialogService.open(ConsultantOfDialogComponent, {
            data: {currentUser, consultants},
            width: '336px',
            height: 'auto',
            closable: false,
            header: 'Kundendaten übertragen im Auftrag von:'
          }).onClose;
        }
        return of({self: true} satisfies AssistanceResult as AssistanceResult);
      }),
      tap(ar => (this.assistanceResult = ar))
    );
  }

  private handleHaushaltLock(uebertragungStatus: UebertragungStatus) {
    this.confirmationService.confirm({
      key: 'softfair-warn',
      header: 'Achtung',
      message: uebertragungStatus.uebertragungErzwingbar ? 'Du bearbeitest den Kunden möglicherweise bereits in einem anderen Fenster!<br>Um Datenverluste zu vermeiden, nutze bitte weiterhin das geöffnete Fenster oder melde dich dort aus dem KonzeptButler ab.'
        : `Zurzeit wird die Kundin/der Kunde von ${uebertragungStatus.gesperrtDurch?.vorname} ${uebertragungStatus.gesperrtDurch?.nachname} (${uebertragungStatus.gesperrtDurch?.email}) im KonzeptButler bearbeitet.<br> Der Aufruf ist daher nicht möglich.`,
      acceptLabel: uebertragungStatus.uebertragungErzwingbar ? 'Trotzdem fortfahren' : 'OK',
      acceptButtonStyleClass: 'p-button-warn',
      rejectLabel: 'Abbrechen',
      rejectButtonStyleClass: 'p-button-text p-button-outlined p-button-plain',
      rejectVisible: uebertragungStatus.uebertragungErzwingbar,
      accept: () => {
      if (uebertragungStatus.uebertragungErzwingbar) {
        this.load(true)
      } else {
        this.closeWindow()
      }
    },
      reject: () => this.closeWindow()
    })
  }

  closeWindow() {
    if (window.opener != null) window.close()
    window.location.href = (this.customer?.anrede === 'Firma' ? '/businesses/' : '/customers/') + this.customer?.id
  }
}
