<div class="mx-auto px-3 flex flex-column justify-content-center align-items-center">
  @if (loading) {
    <p-progressSpinner class="justify-content-center align-items-center mb-4"></p-progressSpinner>
    <div class="text-center">{{message}}</div>
  }
</div>
<p-confirmDialog class="warning-dialog line-height" key="softfair-warn" [appendTo]="null">
  <ng-template pTemplate="rejecticon">
  </ng-template>
  <ng-template pTemplate="accepticon">
  </ng-template>
</p-confirmDialog>

