import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {cache} from "./cache.operator";
import {DateTime} from "luxon";

export interface Agent {
  id: number;
}

export interface AgentInfo {
  vorname: string;
  nachname: string;
  geburtsdatum: DateTime | null;
}

const agentUrl = 't-it-s/rest/crm/v1.0/vp';
const agentInfoUrl = 't-it-s/rest/crm/v1.0/vp/query';


@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(private readonly http: HttpClient) {
  }

  getAgentInfos(filter: { [key: string]: any | any[] }): Observable<AgentInfo[]> {
    let params = new HttpParams();
    for (const key of Object.keys(filter)) {
      params = params.set(key, filter[key]);
    }
    return this.http.get<AgentInfo[]>(agentInfoUrl, {params});
  }

  /**
   * Returns the default 34d registered supervisor of the current logged-in user
   */
  getCurrentSupervisor(): Observable<Agent> {
    return this.http.get<Agent>(`${agentUrl}/34dregistered`).pipe(cache(`34dregistered`));
  }
}
