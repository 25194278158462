import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {PerMaPerson} from '../../services/person-management.service';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {User} from "../../services/user.service";


export interface AssistanceResult {
  self: boolean;
  superior?: string;
  email?: string;
}

export interface ConsultantOfDialogData {
  currentUser: User;
  consultants: PerMaPerson[]
}

@Component({
  selector: 'app-consultant-of-dialog',
  templateUrl: 'consultant-of-dialog.component.html',
  styleUrls: ['./consultant-of-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsultantOfDialogComponent implements OnDestroy {
  user = new UntypedFormControl('', [Validators.required]);
  private destroy = new Subject<void>();
  currentUser: User;
  consultantList: PerMaPerson[];

  constructor(config: DynamicDialogConfig<ConsultantOfDialogData>,
              private dialogRef: DynamicDialogRef<ConsultantOfDialogComponent>) {
    this.user.setValue(config.data?.currentUser.personManagementId);
    this.currentUser = config.data?.currentUser;
    this.consultantList = config.data?.consultants;
    this.addUserToConsultantList(this.currentUser);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  addUserToConsultantList(user: User) {
    this.consultantList.unshift({firstName: user.vorname, lastName: user.nachname, birthDate: user.geburtsdatum, personManagementId: user.personManagementId})
  }

  submit(): void {
    if (this.user.valid) {
      const email = this.consultantList.find(c => c.personManagementId === this.user.value)?.emailAddress
      this.dialogRef.close(this.user.value === this.currentUser.personManagementId ?
        {self: true} as AssistanceResult : {self: false, superior: this.user.value, email } as AssistanceResult);
    }
  }
  //
  // displayName(user: User): string {
  //   return user.vorname + ' ' + user.nachname;
  // }

  displayName(person: PerMaPerson): string {
    return person.firstName + ' ' + person.lastName;
  }

  close() {
    this.dialogRef.close();
  }
}

