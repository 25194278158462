<p-card>
  <ng-template pTemplate="title">
    <div class="flex justify-content-between">
      <span>{{title}}</span>
      @if (errorMessage) {
        <span class="text-red-500 text-sm">{{ errorMessage }}</span>
      }
    </div>
  </ng-template>
  <div class="grid">
    @for (link of links; track link.title) {
      <app-konzept-button class="col-12 sm:col-6 md:col-4"
                          (clicked)="handleLinkClicked($event, link)"
                          [color]="link.color"
                          [class]="link.class"
                          [href]="link.href"
                          [target]="link.target"
                          [disabled]="link.disabled"
                          [hint]="link.hint"
                          [loading]="link.loading">{{ link.title }}
      </app-konzept-button>
    }
  </div>
</p-card>
