import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Address, Contact, Person, PersonService} from "./person.service";
import {cache} from "./cache.operator";

const PERSON_URL = 't-it-s/rest/crm/v1.0/person/';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(private http: HttpClient,
              private personService: PersonService) {
  }

  createBusinessCustomer(data: {
    anrede: string,
    firstName: string,
    lastName: string,
    addresses?: Address[],
    kommunikationsdaten: Contact[],
    vp?: number,
    betreuer?: number,
    ansprechpartner?: string
  }): Observable<Person> {
    let date = null;
    let geschlecht = null;
    switch (data.anrede) {
      case 'Frau':
        geschlecht = 'w';
        break;
      case 'Firma':
        geschlecht = 'f';
        break;
      case 'Herr':
        geschlecht = 'm';
        break;
    }
    return this.http.post<Person>(PERSON_URL, {
      anrede: data.anrede,
      vorname: data.firstName,
      nachname: data.lastName,
      geschlecht,
      geburtsdatum: date,
      adressen: data.addresses,
      kommunikationsdaten: data.kommunikationsdaten,
      kunde: {
        vp: data.vp,
        betreuer: data.betreuer,
        ansprechpartner: data.ansprechpartner
      }
    });
  }

  loadBusinesses(name: string): Observable<Person[]> {
    const options = {params: new HttpParams().set('name', name)};

    return this.http.get<Person[]>(`t-it-s/rest/crm/v1.0/gewerbe`, options);
  }

  loadPersons(name: string): Observable<Person[]> {
    const options = {params: new HttpParams().set('name', name)};
    return this.http.get<Person[]>(`t-it-s/rest/crm/v1.0/person`, options);
  }

  getBusinessByName(name: string): Observable<Person | null> {
    return this.personService.getPersons({lastName: name, companiesOnly: true, exactMatch: true})
      .pipe(
        map(persons => persons?.length > 0 ? persons[0] : null),
        cache(`companyByName:${name}`)
      );
  }
}
