import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrunnenService} from './services/brunnen.service';

import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {PersonResolver} from './services/person-resolver.service';
import {MscPushService} from './services/msc-push.service';
import {AppInitService, AppInitServiceFactory} from './services/app-init.service';
import {OAuthModule} from 'angular-oauth2-oidc';
import {NotDeletedPipe} from './pipes/not-deleted.pipe';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {CrmService} from './services/crm.service';
import {EchseService} from './services/echse.service';
import {PersonManagementService} from './services/person-management.service';
import {ConsultantOfDialogComponent} from './components/consultant-of-dialog/consultant-of-dialog.component';
import {DdeService} from "./services/dde.service";
import {ErstinformationService} from "./services/erstinformation.service";
import {
  CreateCustomerRedirectDialogComponent
} from './components/create-customer-redirect-dialog/create-customer-redirect-dialog.component';
import {PlzService} from "./services/plz-service";
import {UniqueEmployerValidator} from "./components/create-commercial-customer-dialog/unique-employer.validator";
import {KonzeptPageComponent} from './components/konzept-page/konzept-page.component';
import {KonzeptAreaComponent} from './components/konzept-area/konzept-area.component';
import {KonzeptButtonComponent} from './components/konzept-button/konzept-button.component';
import {DatePipe, registerLocaleData} from '@angular/common';
import {SoftfairPushDispatchComponent} from './components/softfair-push-dispatch/softfair-push-dispatch.component';
import {ProblemDetailInterceptor} from "./interceptors/problem-detail-interceptor";
import {TauresHeaderModule} from "@taures/taures-components/taures-header";
import {TauresFooterModule} from "@taures/taures-components/taures-footer";
import {LogoutService} from "./services/logout.service";
import {SubheaderButtonsComponent} from "./components/subheader-buttons/subheader-buttons.component";
import {Button, ButtonDirective} from "primeng/button";
import {CardModule} from "primeng/card";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {DataViewModule} from "primeng/dataview";
import {ListboxModule} from "primeng/listbox";
import {DialogService, DynamicDialog} from "primeng/dynamicdialog";
import {RadioButtonModule} from "primeng/radiobutton";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {TauresMessageModule} from "@taures/taures-components/taures-message";
import {ToastModule} from "primeng/toast";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {TauresToastModule} from "@taures/taures-components/taures-toast";
import {KeycloakTokenService} from "./services/keycloak-token.service";
import {DateParseInterceptor} from "./services/date-parse.interceptor";
import Aura from '@primeng/themes/aura';
import { providePrimeNG } from 'primeng/config';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    NotDeletedPipe,
    ConsultantOfDialogComponent,
    CreateCustomerRedirectDialogComponent,
    KonzeptPageComponent,
    KonzeptAreaComponent,
    KonzeptButtonComponent,
    SoftfairPushDispatchComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    Button,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DynamicDialog,
    OAuthModule.forRoot({resourceServer: {sendAccessToken: true}}),
    TauresHeaderModule,
    TauresFooterModule,
    SubheaderButtonsComponent,
    CardModule,
    FloatLabelModule,
    InputTextModule,
    DataViewModule,
    ListboxModule,
    ButtonDirective,
    RadioButtonModule,
    ProgressSpinnerModule,
    TauresMessageModule,
    ToastModule,
    ConfirmDialogModule,
    TauresToastModule
  ], providers: [
    BrunnenService,
    PersonResolver,
    MscPushService,
    AppInitService,
    ConfirmationService,
    KeycloakTokenService,
    MessageService,
    DialogService,
    CrmService,
    ErstinformationService,
    EchseService,
    PersonManagementService,
    DdeService,
    PlzService,
    UniqueEmployerValidator,
    LogoutService,
    DatePipe,
    {provide: 'LogoutService', useClass: LogoutService},
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitServiceFactory,
      multi: true,
      deps: [AppInitService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateParseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProblemDetailInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    providePrimeNG({
      ripple: true,
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: false,
          cssLayer: {
            name: 'primeng'
          }
        }
      }
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
}
