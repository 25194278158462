import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Observable, ObservableInput, of, Subject} from 'rxjs';

import {catchError, filter, map, mergeMap, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';
import {CrmService} from '../../services/crm.service';
import {MessageService} from "primeng/api";
import {CardModule} from "primeng/card";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {Button} from "primeng/button";
import {ListboxModule} from "primeng/listbox";
import {AsyncPipe} from "@angular/common";
import {Person, PersonService} from "../../services/person.service";

@Component({
  selector: 'app-business-search',
  templateUrl: './business-search.component.html',
  styleUrls: ['./business-search.component.scss'],
  imports: [
    CardModule,
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    Button,
    ListboxModule,
    AsyncPipe
  ],
  standalone: true
})
export class BusinessSearchComponent implements OnDestroy, AfterViewInit {
  customers$: Observable<Person[]>;
  name = new UntypedFormControl('', [this.NoResultsValidator.bind(this), Validators.minLength(2), Validators.required]);
  hasSearched = true;
  emptyResult = false;
  private destroy = new Subject<void>();
  @ViewChild('inputFocusElement') inputElement: ElementRef;
  selectedBusiness = new FormControl<string>('');

  private NoResultsValidator(): ValidationErrors {
    if (this.emptyResult && this.hasSearched) return {noResults: 'No search results'};
    return null;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private crmService: CrmService,
              private personService: PersonService,
              private toastMessageService: MessageService,
              private titleService: Title) {
    this.titleService.setTitle('Konzept erstellen - TauRes');

    this.name.valueChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(() => this.hasSearched = false);

    this.customers$ = this.route.queryParams
      .pipe(
        map(params => params.name),
        tap(name => {
          this.name.reset(name, {emitEvent: false});
          this.emptyResult = false;
        }),
        filter(name => !!name),
        tap(() => this.name.markAsDirty()),
        mergeMap(name => this.crmService.loadBusinesses(name)),
        map(persons => persons.sort((a, b) => {
          return (a.nachname + a.vorname).localeCompare(b.nachname + b.vorname) || a.geburtsdatum.valueOf() - b.geburtsdatum.valueOf();
        })),
        tap(persons => {
          this.emptyResult = persons.length === 0;
          this.name.updateValueAndValidity();
        }),
        catchError((): ObservableInput<Person[]> => {
          this.toastMessageService.add(
            {
              severity: 'error',
              summary: 'Fehler',
              detail: 'Firmendaten können nicht geladen werden.',
            });
          return [];
        }));

    this.selectedBusiness.valueChanges.pipe(
      takeUntil(this.destroy),
      switchMap((permaId) => this.loadBusiness(permaId)),
      filter((permaId) => permaId !== undefined)
    ).subscribe((person: Person) => {
      this.router.navigate(['businesses', person.personManagementId])
    })
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.focus();
  }

  onSubmit(): void {
    if (this.name.valid) {
      this.emptyResult = false
      this.hasSearched = true;
      this.router.navigate(['/businesses'], {
        queryParams: {
          name: this.name.value
        }
      });
    }
  }

  loadBusiness(permaId: string): Observable<Person> {
    return this.personService.loadPerson(permaId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.toastMessageService.add({
              severity: 'error',
              summary: 'Fehler',
              detail: 'Firma nicht gefunden.',
            });
          }
          return of(null);
        })
      )
  }

  searchErrorMessage(): string {
    let message = ''
    if (this.name.hasError('required')) {
      message = 'Name muss angegeben werden'
    } else if (this.name.hasError('noResults')) {
      message = 'Die gesuchte Firma konnte nicht gefunden werden'
    } else if (this.name.hasError('minlength')) {
      message = 'Bitte mindestens zwei Zeichen angeben'
    }
    return message;
  }
}
