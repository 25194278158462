<div class="px-3 mx-auto card-width">
  <div class="flex gap-3 flex-column">
    @for (area of areas; track area.title) {
      <app-konzept-area [title]="area.title"
                        [links]="area.links"
                        [errorMessage]="area.errorMessage">
      </app-konzept-area>
    }
    <a #hiddenLink style="display: none" target="_blank"></a>
  </div>
</div>
<p-confirmDialog class="info-dialog" key="info" [appendTo]="null">
  <ng-template pTemplate="rejecticon">
  </ng-template>
  <ng-template pTemplate="accepticon">
  </ng-template>
</p-confirmDialog>
<p-confirmDialog class="warning-dialog" key="warn" [appendTo]="null">
  <ng-template pTemplate="rejecticon">
  </ng-template>
  <ng-template pTemplate="accepticon">
  </ng-template>
</p-confirmDialog>
