import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import {Router} from "@angular/router";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-create-customer-redirect-dialog',
  templateUrl: './create-customer-redirect-dialog.component.html',
  styleUrls: ['./create-customer-redirect-dialog.component.scss']
})
export class CreateCustomerRedirectDialogComponent {

  constructor(readonly router: Router,
              private dialogRef: DynamicDialogRef<CreateCustomerRedirectDialogComponent>) { }

  toBusinesses() {
    this.router.navigate(['businesses']);
    this.close();
  }

  toPotentialPlaner() {
    window.location.href = environment.potentialPlanerUrl
  }

  close() {
    this.dialogRef.close();
  }
}
