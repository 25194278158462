import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {
  CUSTOMER_LINK_KEY_ANTRAG,
  CUSTOMER_LINK_KEY_FL,
  CUSTOMER_LINK_KEY_LV,
  CUSTOMER_LINK_KEY_PKV
} from './components/konzept-page/konzept-page.component';
import {ActionButtonConfig} from "@taures/taures-components/taures-header";
import {environment} from "../environments/environment";
import {combineLatestWith, EMPTY, fromEvent, Observable} from "rxjs";
import {catchError, filter, map, startWith, switchMap, tap} from "rxjs/operators";
import {UnterschriftService} from "./services/unterschrift.service";
import {KeycloakTokenService} from "./services/keycloak-token.service";
import {PrimeNG} from "primeng/config";
import translations from 'primelocale/de.json';

const defaultTitle = "Konzepterstellung";
const titleByProductKey = {
  [CUSTOMER_LINK_KEY_FL]: 'KonzeptButler',
  [CUSTOMER_LINK_KEY_LV]: 'LV-Modul',
  [CUSTOMER_LINK_KEY_PKV]: 'PKV-Modul',
  [CUSTOMER_LINK_KEY_ANTRAG]: 'Antragsverwaltung'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  username = this.token.name;
  title = null;

  private reload$ = fromEvent(window, 'focus').pipe(startWith(null));

  protected anzahlDokumenteForKunde$: Observable<number> = this.router.events.pipe(
    filter((data) => data instanceof ActivationEnd),
    map((data: ActivationEnd) => data.snapshot?.params?.personManagementId),
    tap((id: string | undefined) => {
      this.kundeId = id;
    }),
    filter((id: string | undefined) => id !== undefined),
    combineLatestWith([this.reload$]),
    switchMap(([id]) => this.unterschriftService.getAnzahlNeueDokumente(id)),
    catchError(() => {
      return EMPTY;
    })
  );

  kundeId: string | undefined;

  protected unterschriftIcon$: Observable<ActionButtonConfig> = this.anzahlDokumenteForKunde$.pipe(
    map(anzahl => ({
          iconClass: 'material-symbols-outlined',
          icon: 'signature',
          tooltip: 'Zum Unterschriften-Manager',
          badge: anzahl > 0 ? anzahl.toString() : '',
          badgeSeverity: 'warn',
          callback: () => this.openInUnterschriftManager()
        } satisfies ActionButtonConfig
      )
    )
  )

  constructor(private token: KeycloakTokenService,
              private router: Router,
              private changeDetector: ChangeDetectorRef,
              private unterschriftService: UnterschriftService,
              private primeNG: PrimeNG) {
  }

  ngOnInit() {
    this.primeNG.setTranslation(translations.de);
    this.router.events.subscribe(data => {
      if (data instanceof ActivationEnd) {
        const productKey = data.snapshot?.params?.productKey
        this.title = productKey ? titleByProductKey[productKey] || defaultTitle : defaultTitle
        this.changeDetector.markForCheck()
      }
    })
  }

  private openInUnterschriftManager() {
    window.open(environment.unterschriftUrl + '/person/' + this.kundeId);
  }
}
