<div class="search-width justify-self-center px-3 mx-auto">
  <p-card header="Kunde auswählen">
    <form (submit)="onSubmit()" novalidate>
      <div class="flex gap-3 align-items-center mb-3">
        <p-floatlabel class="w-full">
          <input #search id="search" pInputText type="text" [formControl]="name" class="w-full">
          <label for="search">Name</label>
          @if (name.invalid && name.dirty) {
            <small class="search-error">{{ searchErrorMessage() }}</small>
          }
        </p-floatlabel>
        <p-button icon="pi pi-search" [rounded]="true" severity="help" [disabled]="name.invalid" (click)="onSubmit()"/>
      </div>
    </form>
    @let customers = customers$ | async;
    @if (customers?.length) {
      <p-listbox scrollHeight="auto" [options]="customers" optionLabel="personManagementId" optionValue="personManagementId" [formControl]="selectedPerson">
        <ng-template let-person pTemplate="item">
          <div class="flex justify-content-between w-full">
            <span>{{ person.lastName }}, {{ person.firstName }}</span>
            <span>{{ person.birthDate | date: 'dd.MM.yyyy' }}</span>
          </div>
        </ng-template>
      </p-listbox>
    }
  </p-card>
</div>
