import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {catchError, take, tap} from 'rxjs/operators';
import {Person, PersonService} from "./person.service";

@Injectable()
export class PersonResolver  {
  constructor(private personService: PersonService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Person> {
    const personManagementId = route.paramMap.get('personManagementId');
    return this.personService.getPerson(personManagementId)
      .pipe(
        take(1),
        tap((customer: Person) => {
          if (!state.url.includes('businesses') && customer?.anrede === 'Firma') {
            this.router.navigate(['businesses', customer.personManagementId]);
          }
        }),
        catchError(() => {
          this.router.navigate(['/']);
          return of(null);
        }));
  }
}
