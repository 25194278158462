<p-button class="konzept-button p-0 flex w-full label-font-weight button-height align-content-center justify-content-center overflow-hidden text-overflow-ellipsis"
          (click)="handleClick($event)"
          [loading]="loading"
          [severity]="color || 'primary'"
          [ngClass]="!disabled && !loading ? class : null"
          [disabled]="disabled || loading">
  <ng-content></ng-content>
</p-button>
@if (hint) {
  <div class="text-xs py-1 text-center">{{hint}}</div>
}


