import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Observable, ObservableInput, of, Subject} from 'rxjs';

import {catchError, filter, map, mergeMap, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {PerMaPerson} from '../../services/person-management.service';
import {HttpErrorResponse} from '@angular/common/http';
import {CrmService} from "../../services/crm.service";
import {CardModule} from "primeng/card";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {Button} from "primeng/button";
import {ListboxModule} from "primeng/listbox";
import {AsyncPipe, DatePipe} from "@angular/common";
import {ToastMessageEmitterService, ToastSeverity} from "@taures/taures-components/taures-toast";
import {Person, PersonService} from "../../services/person.service";


@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss'],
  imports: [
    CardModule,
    FloatLabelModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    Button,
    ListboxModule,
    AsyncPipe,
    DatePipe
  ],
  standalone: true
})
export class CustomerSearchComponent implements OnDestroy, AfterViewInit {
  customers$: Observable<PerMaPerson[]>;
  name = new UntypedFormControl('', [this.NoResultsValidator.bind(this), Validators.minLength(2), Validators.required]);
  hasSearched = false;
  emptyResult = false;
  private destroy = new Subject<void>();
  @ViewChild('search') inputElement: ElementRef;
  selectedPerson = new FormControl<string>('');

  private NoResultsValidator(): ValidationErrors {
    if (this.emptyResult && this.hasSearched) return {noResults: 'No search results'};
    return null;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private personService: PersonService,
              private toastMessageService: ToastMessageEmitterService,
              private crmService: CrmService,
              private titleService: Title) {
    this.titleService.setTitle('Konzept erstellen - TauRes');

    this.name.valueChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(() => this.hasSearched = false);

    this.customers$ = this.route.queryParams
      .pipe(
        map(params => params.name),
        tap(name => {
          this.name.reset(name, {emitEvent: false});
          this.emptyResult = false;
        }),
        filter(name => !!name),
        tap(() => this.name.markAsDirty()),
        mergeMap(name => this.crmService.loadPersons(name)),
        map(persons => {
          return persons.map(person => CustomerSearchComponent.isPerMaPerson(person) ? person : CustomerSearchComponent.asPerMaPerson(person));
        }),
        map(persons => persons.sort((a, b) => {
          return (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName) || a.birthDate.valueOf() - b.birthDate.valueOf();
        })),
        tap(persons => {
          this.emptyResult = persons.length === 0;
          this.name.updateValueAndValidity();
        }),
        catchError((): ObservableInput<PerMaPerson[]> => {
          this.toastMessageService.publishToastMessage({
            severity: ToastSeverity.TECHNICAL_ERROR,
            messageTitle: 'Fehler',
            message: 'Kundendaten können nicht geladen werden.',
          });
          return [];
        }));

    this.selectedPerson.valueChanges.pipe(
      takeUntil(this.destroy),
      switchMap((permaId) => this.loadPerson(permaId)),
      filter((permaId) => permaId !== undefined)
    ).subscribe((person: Person) => {
      this.router.navigate(['customers', person.personManagementId])
    })
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.focus();
  }

  onSubmit(): void {
    if (this.name.valid) {
      this.emptyResult = false
      this.hasSearched = true;
      this.router.navigate(['/customers'], {
        queryParams: {
          name: this.name.value
        }
      });
    }
  }

  loadPerson(permaId: string): Observable<Person> {
    return this.personService.loadPerson(permaId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.toastMessageService.publishToastMessage({
              severity: ToastSeverity.USER_OPERATION_ERROR,
              messageTitle: 'Fehler',
              message: 'Kunde nicht gefunden.',
            });
          }
          return of(null);
        })
      )
  }

  static isPerMaPerson(person: Person | PerMaPerson): person is PerMaPerson {
    return "lastName" in person && person.lastName !== undefined;
  }

  static asPerMaPerson(person: Person): PerMaPerson {
    return {
      firstName: person.vorname,
      lastName: person.nachname,
      birthDate: person.geburtsdatum,
      personManagementId: person.personManagementId
    }
  }

  searchErrorMessage(): string {
    let message = ''
    if (this.name.hasError('required')) {
      message = 'Name muss angegeben werden'
    } else if (this.name.hasError('noResults')) {
      message = 'Die gesuchte Person konnte nicht gefunden werden'
    } else if (this.name.hasError('minlength')) {
      message = 'Bitte mindestens zwei Zeichen angeben'
    }
    return message;
  }
}
