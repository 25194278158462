import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AbstractLogoutService } from '@taures/taures-components/taures-header';

@Injectable({providedIn: 'root'
})
export class LogoutService implements AbstractLogoutService {
  constructor(private oAuthService: OAuthService) {}

  public logout(): void {
    this.oAuthService.logOut();
  }
}
