import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {DateTime} from "luxon";

export interface PerMaPerson {
  firstName: string;
  lastName: string;
  birthDate: DateTime;
  personManagementId: string;
  emailAddress?: string;
}

@Injectable()
export class PersonManagementService {

  constructor(private http: HttpClient) { }

  isAssistantOf(permaId: string): Observable<PerMaPerson[]> {
    return this.http.get<PerMaPerson[]>(`/perma/consultants/assisted/${permaId}?relationType=CONSULTING_ASSISTANT_OF`);
  }
}
