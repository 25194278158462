@let person = (person$ | async);
@if (person) {
  <div class="flex align-items-center">
    @if (onBusinessSide()) {
      <h1 class="bodytext-regular-singleline pr-1-half">{{ [person.vorname, person.nachname].join(' ').trim() }}
        , {{ person.anrede }}</h1>
    } @else {
      <h1
        class="bodytext-regular-singleline pr-1-half">{{ person.nachname + ', ' + person.vorname }} {{ person.geburtsdatum | date: 'dd.MM.yyyy' }}</h1>
    }
    <p-button icon="pi pi-folder" pTooltip="Stammdaten CRM" [text]="true" tooltipPosition="left" [rounded]="true"
              (onClick)="openInCrm(person.id)">
    </p-button>
    <p-button (onClick)="back()" class="material-icon-button" [text]="true" pTooltip="Kunden wechseln" [rounded]="true">
      <span class="material-symbols-outlined">person_search</span>
    </p-button>
  </div>
} @else if (onBusinessSide()) {
  <p-button (click)="createCommercialCustomer()" [text]="true" [rounded]="true"
            pTooltip="Neuen Gewerbekunden manuell anlegen" [disabled]="loading">
    <svg width="21" height="21" viewBox="0 0 384 226.74" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill="#1F396ADE"
            d="M382.75,74.19l-13.23-55.05c-2.77-11.34-12.6-19.15-24.06-19.15H170.5c-11.34,0-21.29,7.94-23.93,19.15l-13.23,55.05c-3.02,12.85-.25,25.95,7.81,36.28,1.01,1.39,2.39,2.39,3.53,3.65v87.42c0,13.86,11.34,25.19,25.19,25.19h176.35c13.86,0,25.19-11.34,25.19-25.19V114.12c1.13-1.13,2.52-2.27,3.53-3.53,8.06-10.33,10.96-23.56,7.81-36.4Zm-37.66-49.13l13.23,55.05c1.26,5.29,.13,10.58-3.15,14.74-1.76,2.27-5.54,5.92-11.84,5.92-7.68,0-14.36-6.17-15.24-14.36l-7.31-61.22,24.31-.13Zm-74.45,.13h24.69l6.8,56.94c.63,4.91-.88,9.83-4.16,13.48-2.77,3.28-6.8,5.16-11.97,5.16-8.44,0-15.37-7.43-15.37-16.5V25.19Zm-56.81,56.94l6.93-56.94h24.69v59.08c0,9.07-6.93,16.5-16.25,16.5-4.28,0-8.19-1.89-11.21-5.16-3.15-3.65-4.66-8.57-4.16-13.48Zm-56.05-2.02l12.72-54.92h24.82l-7.31,61.22c-1.01,8.19-7.56,14.36-15.24,14.36-6.17,0-10.08-3.65-11.71-5.92-3.4-4.03-4.53-9.45-3.28-14.74Zm12.09,121.43V125.59c1.01,.13,1.89,.38,2.9,.38,10.96,0,20.91-4.53,28.22-11.97,7.56,7.56,17.64,11.97,29.1,11.97s20.78-4.53,28.09-11.71c7.43,7.18,17.51,11.71,28.85,11.71,10.58,0,20.66-4.41,28.22-11.97,7.31,7.43,17.26,11.97,28.22,11.97,1.01,0,1.89-.25,2.9-.38v75.96H169.87Z"/>
      <path fill="#1F396ADE"
            d="M71.25,113.99v-42.75h42.75v-28.5h-42.75V0h-28.5V42.75H0v28.5H42.75v42.75h28.5Z"/>
    </svg>
  </p-button>
} @else {
  <p-button icon="pi pi-user-plus" [text]="true" [rounded]="true" (click)="createPerson()"
            pTooltip="Neuen Kunden manuell anlegen" [disabled]="loading">
  </p-button>
}
