<div class="search-businesses-width mx-auto justify-self-center px-3">
  <p-card header="Firma auswählen">
    <form (submit)="onSubmit()" novalidate>
      <div class="flex gap-3 align-items-center mb-3">
        <p-floatlabel class="w-full">
          <input #inputFocusElement id="search" pInputText type="text" [formControl]="name" class="w-full">
          <label for="search">Firmenname</label>
          @if (name.invalid && name.dirty) {
            <small class="search-error">{{ searchErrorMessage() }}</small>
          }
        </p-floatlabel>
        <p-button icon="pi pi-search" [rounded]="true" severity="help" [disabled]="name.invalid" (click)="onSubmit()"/>
      </div>
    </form>
    @let customers = customers$ | async;
    @if (customers?.length) {
    <p-listbox [options]="customers" optionLabel="personManagementId" optionValue="personManagementId"
               [formControl]="selectedBusiness">
      <ng-template let-person pTemplate="item">
        <span class="flex-grow">{{ [person.vorname, person.nachname].join(' ').trim() }}</span>
      </ng-template>
    </p-listbox>
    }
  </p-card>
</div>
