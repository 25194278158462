<div>
  <p class="mb-2 dialog-subtitle-size"><b>Natürliche Person anlegen</b></p>
  <p class="mb-3 mt-0">Die Anlage von natürlichen Personen erfolgt über den Potential Planer.</p>
  <div class="text-center mb-3">
    <p-button label="Zum Potential Planer" [outlined]="true" (click)="toPotentialPlaner()" class="w-4 font-medium"></p-button>
  </div>
  <p class="mb-2 dialog-subtitle-size"><b>Juristische Person anlegen</b></p>
  <p class="mb-3 mt-0">Zur Anlage einer juristischen Person steht die Gewerbeplattform zur Verfügung.</p>
  <div class="text-center">
    <p-button label="Zur Gewerbeplattform" [outlined]="true" (click)="toBusinesses()" class="w-4 font-medium"></p-button>
  </div>
</div>
<div class="flex justify-content-end pt-1">
  <p-button label="OK" (click)="close()"></p-button>
</div>
