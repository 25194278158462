<p-fluid>
  <p-floatlabel>
    <p-autoComplete id="user"
                    class="p-fluid"
                    [formControl]="control"
                    [required]="required"
                    (blur)="onTouched.call()"
                    [suggestions]="filteredElements | async"
                    [optionLabel]="displayName"
                    (onSelect)="onUserChange($event)">
    </p-autoComplete>
    <label for="user">{{ placeholder }}</label>
  </p-floatlabel>
</p-fluid>
