import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {Country, COUNTRY_CODES} from "../../services/country-codes";
import {of, Subject, switchMap} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {CrmService} from "../../services/crm.service";
import {PlzService} from "../../services/plz-service";
import {UniqueEmployerValidator} from "./unique-employer.validator";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {NgIf} from "@angular/common";
import {TauresMessageModule} from "@taures/taures-components/taures-message";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputTextModule} from "primeng/inputtext";
import {AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {DropdownModule} from "primeng/dropdown";
import {Button} from "primeng/button";
import {UserAutocompleteComponent} from "../user-autocomplete/user-autocomplete.component";
import {Address, Contact, Person, PersonService} from "../../services/person.service";
import {UserService} from 'src/app/services/user.service';
import {AgentService} from "../../services/agent.service";
import {ToastMessageEmitterService, ToastSeverity} from "@taures/taures-components/taures-toast";
import {Select} from "primeng/select";
import {Fluid} from "primeng/fluid";

@Component({
  selector: 'app-create-commercial-customer-dialog',
  templateUrl: './create-commercial-customer-dialog.component.html',
  styleUrls: ['./create-commercial-customer-dialog.component.scss'],
  imports: [
    ReactiveFormsModule,
    NgIf,
    FormsModule,
    TauresMessageModule,
    FloatLabelModule,
    InputTextModule,
    AutoCompleteModule,
    Select,
    Button,
    UserAutocompleteComponent,
    Fluid
  ],
  standalone: true
})

export class CreateCommercialCustomerDialogComponent implements OnInit, OnDestroy {
  plzValidator = Validators.pattern(/^[0-9]{5}$/);
  form: UntypedFormGroup;
  includeConsultantFilter = {};
  betreuerFilter = {only34d: true, excludeInactive: true};
  loading = false;
  cityList: string[] = [];
  filteredCities: string[] = [];
  sortedCountries: Country[] = COUNTRY_CODES;
  private destroy = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private personService: PersonService,
    private userService: UserService,
    private agentService: AgentService,
    private changeDetectorRef: ChangeDetectorRef,
    private crmService: CrmService,
    private dialogRef: DynamicDialogRef<CreateCommercialCustomerDialogComponent>,
    private toastMessageService: ToastMessageEmitterService,
    private plzService: PlzService,
    readonly uniqueEmployerValidator: UniqueEmployerValidator
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(50)], [CreateCommercialCustomerDialogComponent.getUniqueEmployerValidation(this.uniqueEmployerValidator).bind(this)]],
      street: [null, [Validators.required, Validators.maxLength(255)]],
      number: [null, [Validators.required]],
      co: ['', [Validators.maxLength(255)]],
      postalCode: [null, [Validators.required, this.plzValidator]],
      city: [null, [Validators.required, Validators.maxLength(255)]],
      country: [COUNTRY_CODES[0].code, [Validators.required]],
      phone: [null, [Validators.pattern(/^([+0])\d+[\d \/\-.]*\d+$/)]],
      mail: [null, [Validators.email]],
      vp: null,
      betreuer: null,
      ansprechpartner: [null, Validators.maxLength(255)]
    });
    this.userService.getCurrentUser().pipe(takeUntil(this.destroy)).subscribe(currentUser => {
      this.form.get('vp').setValue(currentUser.id);
    });
    this.agentService.getCurrentSupervisor().pipe(takeUntil(this.destroy)).subscribe(supervisor => {
      this.form.get('betreuer').setValue(supervisor.id);
    });
  }

  static getUniqueEmployerValidation(uniqueEmployerValidator: UniqueEmployerValidator) {
    return (control: AbstractControl) => {
      return uniqueEmployerValidator
        .checkIfDuplicateExists(control.value);
    };
  }

  ngOnInit(): void {
    this.form.get('postalCode')?.valueChanges.pipe(
      takeUntil(this.destroy),
      filter(change => change !== undefined),
      switchMap(change => {
        let plz = this.form.get('postalCode');
        if (plz?.valid) {
          return this.plzService.getCities(plz.value, this.form.get('country')?.value);
        }
        return of([]);
      })
    ).subscribe((cities: string[]) => {
      this.cityList = cities;
      this.changeDetectorRef.markForCheck();
    });

    this.form.get('country')?.valueChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe((change: Country) => {
      if (change.code == COUNTRY_CODES[0].code) {
        this.form.get('postalCode')?.addValidators(this.plzValidator);
      } else {
        this.form.get('postalCode')?.removeValidators(this.plzValidator);
      }
      this.form.get('postalCode')?.updateValueAndValidity();
    })
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.form.valid) {
      const newEmployer = {
        anrede: 'Firma',
        firstName: '',
        lastName: this.form.get('name')?.value,
        addresses: [this.generateAddress()],
        kommunikationsdaten: this.generateContacts(),
        ansprechpartner: this.form.get('ansprechpartner')?.value,
      };
      this.crmService.createBusinessCustomer(newEmployer)
        .pipe(takeUntil(this.destroy))
        .subscribe(person => this.dialogRef.close(person), error => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            const customer = error.error as Person;
            if (!customer.kunde) {
              this.toastMessageService.publishToastMessage({
                severity: ToastSeverity.USER_OPERATION_ERROR,
                messageTitle: 'Fehler',
                message: 'Dieser Kunde existiert schon im System.',
              });
              this.dialogRef.close();
              return;
            }
            this.personService.getPerson(customer.kunde.betreuer)
              .pipe(takeUntil(this.destroy))
              .subscribe(
                consultant => {
                  if (consultant) {
                    this.toastMessageService.publishToastMessage({
                      severity: ToastSeverity.USER_OPERATION_ERROR,
                      messageTitle: 'Fehler',
                      message: `Dieser Kunde existiert schon im System und wird
                        von ${consultant.vorname} ${consultant.nachname} betreut.
                        Um Zugriff auf diesen Kunden zu erhalten,
                        lasse dich bitte als Stellvertreter eintragen.`,
                    });
                    this.dialogRef.close();
                  }
                });
          }
        });
    }
  }

  generateAddress(): Address {
    return {
      land: this.form.get('country')?.value,
      strasse: this.form.get('street')?.value,
      nummer: this.form.get('number')?.value,
      adresseco: this.form.get('co')?.value,
      plz: this.form.get('postalCode')?.value,
      stadt: this.form.get('city')?.value
    };
  }

  generateContacts(): Contact[] {
    const mail: Contact = {
      typ: 1,
      vorrang: true,
      eintrag: this.form.get('mail')?.value
    };
    const phone: Contact = {
      typ: 7,
      vorrang: true,
      eintrag: this.form.get('phone')?.value
    };
    return [mail, phone];
  }

  getCities(): string[] {
    return this.cityList;
  }

  hasNumberInStreet(): boolean {
    const value: string = this.form.get('street')?.value;
    if (value && !this.form.get('street')?.disabled) {
      return value.search(/\d/) != -1;
    }
    return false;
  }

  public hasDuplicateEmployer(): boolean {
    return this.form.get('name')?.hasError('uniqueEmployer') ?? false;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  search(event: AutoCompleteCompleteEvent) {
      this.filteredCities = this.getCities().filter(city => city.toLowerCase().includes(event.query.toLowerCase()));
  }
}
